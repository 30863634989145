.MuiSvgIcon-root {
  background-color: #005cb8;
  border-radius: 5px;
  color: white;
}
.acordeon {
  box-shadow: none !important;
  border-bottom: solid 1px #d8d8d8 !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.contacto-margin {
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: 'Lato', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', -apple-system, BlinkMacSystemFont,
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px !important;
  color: #1c103b !important;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: auto !important;
}

footer {
  background-color: #1976d2;
  color: #ffffff;
}
footer a {
  text-decoration: none;
  color: #ffffff;
}
.page-footer {
  background-image: url(./Images/fondos/footer.png);
  background-position: center;
  background-size: 100%;
  margin: 0;
}
.page-footer-borde {
  background-image: url(./Images/fondos/footer-linea.png);
  background-size: 100%;
  width: 100%;
  height: 41px;
}

.copyright {
  text-align: center;
}

.contenedor-centro {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.item-centro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fondo-icono {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: -30px;
}
.fondo-icono img {
  height: 28px;
  width: auto;
}
.contacto-color-1 {
  border: solid 2px #0254bf;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  margin: 0 20px !important;
  position: relative;
  background-color: #ffffff;
}
.contacto-color-1 .fondo-icono {
  background-color: #0254bf;
}
.contacto-color-2 {
  border: solid 2px #00c0de;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  margin: 0 20px !important;
  position: relative;
  background-color: #ffffff;
}
.contacto-color-2 .fondo-icono {
  background-color: #00c0de;
}

.texto-centrado {
  text-align: center;
}

.contenedor-testimonios {
  background-color: white;
  border-radius: 0 0 10px 10px;
  border: 1px solid #3a86ff;
  margin-top: -25px;
  padding: 10px;
}

.separacion {
  padding: 5%;
}

/* imagenes */
.imagen_sobrepuesta {
  position: relative;
  top: -137px;
  width: 100%;
}
.imagen_sobrepuesta_2 {
  position: relative;
  top: -20px;
  width: 100%;
}

.imagen-equipo {
  border-radius: 50%;
  overflow: hidden;
  width: 200px; /* Ajusta el tamaño de acuerdo a tus necesidades */
  height: 200px; /* Ajusta el tamaño de acuerdo a tus necesidades */
}

.imagen-testimonios {
  border-radius: 10px 10px 0 0;
  width: 100%;
}
.img-footer {
  margin-top: 30px;
  margin-bottom: 15px;
}

/* imagenes */

/* fondos */
.fondo-1 {
  background-image: url(Images/fondos/1.png);
  background-position: center;
  background-color: #f8f8f8;
  width: 100%;
}

.fondo-2 {
  background-image: url(Images/fondos/2.png);
  background-position: center;
  background-color: #f8f8f8;
  width: 100%;
}

.fondo-3 {
  background-image: url(Images/fondos/3.png);
  background-position: center;
  background-color: #f8f8f8;
  width: 100%;
}
/* fin fondos */

/* header */
.logo-header {
  width: 70%;
}

.line-header {
  width: 70%;
  height: 2px;
  background-color: #ebe5f7;
}
/* header */

/* botones */
.boton-1 {
  background: #00c0de;
  color: #ffffff;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
  padding: 7px 10px;
  border-radius: 10px;
  width: fit-content;
}
.boton-2 {
  background: #00c0de;
  color: #ffffff;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  padding: 14px 25px;
  border-radius: 10px;
  text-decoration: none;
  width: fit-content;
}
.posicion-boton {
  margin-top: 50px;
}

.boton-enviar {
  background: #00c0de !important;
  color: #ffffff !important;
  font-weight: bolder !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  padding: 14px 25px !important;
  border-radius: 10px !important;
  text-decoration: none;
  max-width: 60%;
  width: 100%;
  width: fit-content;
}
/* botones */

/* textos y titulos */
.titulo-contacto {
  margin-top: 25px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: bolder;
  color: #0e081e;
}
.titulo-testimonios {
  font-size: 20px;
  color: #283646;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
}

.posicion-texto-testimonios {
  padding: 0 25%;
  text-align: center;
}

.titulo-1 {
  color: #005cb8;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
  margin: 0;
}
.titulo-2 {
  color: #0b0417;
  font-family: 'Poppins', sans-serif;
  font-size: 34px;
  font-weight: bolder;
  text-align: center;
  margin: 0;
}
.titulo-2-1 {
  color: #0b0417;
  font-family: 'Poppins', sans-serif;
  font-size: 34px;
  font-weight: bolder;
  margin: 0;
}
.titulo-3 {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: bolder;
  margin: 0;
}
.titulo-4 {
  color: #0b0417;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 30px;
}
.titulo-5 {
  color: #005cb8;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: bolder;
  margin: 0;
}

.texto-1 {
  font-size: 15px;
}
.texto-2 {
  font-size: 15px;
}
.texto-3 {
  font-size: 16px;
  color: #77808b;
  font-weight: bolder;
}
.texto-acordeon {
  font-size: 16px;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #1c103b;
}
.titulo-footer {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}
.titulo-footer-2 {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  margin-top: 65px;
  text-transform: uppercase;
}
.texto-footer {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
}
.alineacion-texto {
  text-align: initial;
}

/* textos y titulos */

/* contenedores */
.css-10hburv-MuiTypography-root {
  font-size: 13px !important;
}
.margin-links-footer {
  margin-top: 20px !important;
}
.contenedor-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.centrar-movil-2 {
  display: flex;
  justify-content: start;
}
/* contenedores */

/* especialidades */

.especialidades-redireccion {
  font-weight: bold;
  color: #fff;
}

/* card */

.card {
  position: relative;
  width: 270px;
  height: 175px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  background-image: url(./Images/marco.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.card::before,
.card::after {
  position: absolute;
  content: '';
  width: 0%;
  height: 0%;
  font-weight: bold;
  background-color: #00c0de;
  transition: all 0.5s;
}

.card::before {
  top: 0;
  right: 0;
  border-radius: 0 10px 0 100%;
}

.card::after {
  bottom: 0;
  left: 0;
  border-radius: 0 100% 0 10px;
}
.card:hover::before {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: 100%;
  transition: all 0.5s;
}
.card:hover:after {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-image: url(./Images/marco-2.png);
  background-repeat: no-repeat;
  background-size: 100%;
  transition: all 0.5s;
}

.icon-container {
  opacity: 0;
  position: absolute;
  color: #ffffff;
  padding: 10px 35px 35px 35px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  animation: disappear 0.3s ease;
}
.card:hover .icon-container {
  content: '';
  position: relative;
  z-index: 999;
  opacity: 1;
  animation: appear 0.3s ease;
}

.texto-tarjeta p {
  margin: 0;
  font-size: 12px;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* card */
/* especialidades */

/* equipo */
.container-equipo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  position: relative;
  width: 200px;
  height: 200px;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.box::before {
  content: '';
  position: absolute;
  inset: -5px 70px;
  /* background: linear-gradient(315deg, #00ccff, #d400d4); */
  background: #00c0de;
  transition: 0.5s;
}
.direccion-0::before {
  animation: border-animation 6s linear infinite;
}
.direccion-1::before {
  animation: border-animation-2 6s linear infinite;
}
.box:hover::before {
  inset: -20px 0px;
}
.equipo-color-1::before {
  background: #002f86;
  /* font-weight: bold;
    text-align: center; */
}

.equipo-color-2::before {
  background: #00c0de;
  /* font-weight: bold;
    text-align: center; */
}

.equipo-color-3::before {
  background: #0254bf;
  /* font-weight: bold;
    text-align: center; */
}

@keyframes border-animation-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes border-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.box::after {
  content: '';
  position: absolute;
  inset: 3px;
  background: #fff;
  border-radius: 50%;
  z-index: 1;
}
.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  inset: 15px;
  /* border: 3px solid #00C0DE; */
  border-radius: 50%;
  overflow: hidden;
  z-index: 3;
}
.content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: 0.75s;
  pointer-events: none;
  z-index: 3;
}
/* equipo */

/* formulario */
.MuiInputLabel-root {
  left: -15px !important;
  font-size: 22px !important;
  font-weight: 400 !important;
  font-family: Poppins !important;
  color: #002f86 !important;
}

.MuiInputBase-root .MuiSvgIcon-root {
  background-color: transparent;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  display: contents !important;
}
.css-c252ls-MuiFormControl-root-MuiTextField-root .MuiInputBase-input {
  width: inherit !important;
}
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  padding-top: 0 !important;
}
/* formulario */

/* carrusel */
.flechas-carrusel {
  height: 100%;
  display: flex;
  align-items: center;
}
/* fin -carrusel */

.lista-servicios {
  list-style-image: url(./Images/Icons/5_1.svg);
}

.invisible {
  display: none;
}

.margin-top-1 {
  margin-top: 20px;
}

@media only screen and (min-width: 200px) and (max-width: 550px) {
  .visible-movil {
    display: block;
  }

  .card::before,
  .card::after {
    position: relative;
    display: none;
  }

  .margin-top-1 {
    margin-top: 0px;
  }

  .page-footer-borde {
    background-image: url(./Images/fondos/footer-linea-movil.png);
    background-size: auto;
    width: 100%;
    height: 26px;
  }

  .box {
    height: 176px;
    width: 176px;
  }

  .movil-none {
    display: none;
  }

  .centrar-movil {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centrar-movil-1 {
    justify-content: center;
  }

  .centrar-movil-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contenedor-1 {
    gap: 10px 0;
  }

  .card {
    width: 179px;
    height: 120px;
  }

  .imagen_sobrepuesta {
    top: -90px;
  }

  .imagen_sobrepuesta_2 {
    margin-bottom: -40px;
    top: -50px;
  }

  .alineacion-texto {
    text-align: center;
  }
  .grid-movil {
    padding-left: 40px !important;
  }
  .grid-movil-2 {
    padding-left: 25px !important;
  }
  .margin-links-footer {
    margin-top: 10px !important;
  }
  .titulo-footer-2 {
    margin-top: 15px;
  }
  .contacto-margin {
    margin: 0 1px !important;
    min-height: 120px;
  }
  .texto-3 {
    font-size: 16px;
    color: #77808b;
    font-weight: bolder;
    text-align: center;
  }

  .titulo-footer {
    font-size: 15px;
  }
  .titulo-footer-2 {
    font-size: 15px;
  }
  .texto-footer {
    font-size: 15px;
  }
  .css-10hburv-MuiTypography-root {
    font-size: 15px !important;
  }

  .posicion-boton {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .posicion-texto-testimonios {
    padding: 0 5%;
    text-align: center;
  }
  .texto-posicion {
    text-align: center;
  }
  .imagen-v-movil {
    width: 100%;
  }

  .icon-container {
    padding: 10px 15px;
  }
  .texto-tarjeta p {
    margin: 0;
    font-size: 10px;
  }
  .especialidades-redireccion {
    font-size: 10px;
  }
  .titulo-3 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 992px) {
  .visible-tablet {
    display: block;
  }

  .posicion-boton {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 25px;
  }

  .card::before,
  .card::after {
    position: relative;
    display: none;
  }

  .centrar-movil-1 {
    justify-content: center;
  }

  .centrar-movil-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .texto-posicion {
    text-align: center;
  }

  .logo-header {
    width: 100%;
  }

  .page-footer-borde {
    background-image: url(./Images/fondos/footer-linea.png);
    background-size: auto;
    width: 100%;
    height: 55px;
  }

  .titulo-footer {
    font-size: 15px !important;
    text-align: center;
  }
  .titulo-footer-2 {
    font-size: 15px !important;
    text-align: center;
  }
  .texto-footer {
    font-size: 15px !important;
    text-align: center;
  }
  .css-10hburv-MuiTypography-root {
    font-size: 15px !important;
    text-align: center;
  }

  .imagen_sobrepuesta {
    top: -90px;
  }

  .imagen_sobrepuesta_2 {
    top: -80px;
    margin-bottom: -70px;
  }

  .alineacion-texto {
    text-align: center;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1664px) {
  .texto-posicion {
    text-align: inherit !important;
  }
}

@media only screen and (min-width: 1665px) {
  .texto-posicion {
    text-align: inherit !important;
  }
}
